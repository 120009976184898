import { Component, Provide, Vue } from "vue-property-decorator";
import type { DataOptions } from "vuetify";
import { isEmpty } from "lodash";
import { ref } from "@/services/Utils";

@Component
export default class PaginateProviderMixin extends Vue {
  @Provide() public currentPageRef = ref<number>(0);
  @Provide() public paginationRef = ref<Partial<DataOptions>>({});
  @Provide() noParamPage = ref<boolean>(false);
  @Provide() public currentMovementTypeCodeRef = ref<number>(0);

  get paramPage() {
    return this.$route.query?.page ? Number(this.$route.query.page) : 1;
  }

  created() {
    if (!this.currentPageRef.value) {
      this.currentPageRef.value = this.paramPage;
    }

    if (isEmpty(this.paginationRef.value)) {
      this.paginationRef.value = {
        page: this.paramPage,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [false],
        multiSort: false,
        mustSort: false,
      };
    }
  }
}
